.account-wrapper {
	h2 {
		font-weight: 700;
		font-size: 42px;
		line-height: 57px;
		letter-spacing: 0.02em;
		color: #333333;
	}

	h3 {
		font-weight: 600;
		font-size: 24px;
		line-height: 30px;
		letter-spacing: -1px;
		color: #333333;
	}
}