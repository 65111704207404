@import "variables";

/******* Base styles *******/
html{
    height: 100%;
}
body{
    height: 100%;
    margin:0; padding: 0;
    font-family: $font-family;
    font-size: 14px;
    line-height: 1.5;
}
*{
    margin: 0;
    padding: 0;
}
a{    
    outline: none;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.h-100 {
    height: 100% !important;
}
.w-100{
    width: 100% !important;
}
.mw-100{
    max-width: 100%;
}
.fw-300{
    font-weight: 300;
}
.fw-400{
    font-weight: 400;
}
.fw-500{
    font-weight: 500;
}
.lh{
    line-height: 27px;
}
.theme-container{
    max-width: $theme-max-width;
    margin: 0 auto;
    width: 100%;
}
.light-block.mat-mdc-card{
    background: rgba(0,0,0,0.03) !important;
    box-shadow: none !important;    
}
.transition{
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
}
.text-truncate{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.text-left{
    text-align: left;
}
.divider{
    border-top: 1px solid rgba(204, 204, 204, 0.6);
}

.mat-mdc-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs{
    font-size: 14px;
    line-height: 14px;
    height: 14px;
    width: 14px;
    vertical-align: middle;
}
.mat-mdc-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.mat-nav-list .mat-mdc-list-item .mat-mdc-list-item-icon.mat-icon-sm{
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    width: 18px;
    vertical-align: middle;
}
.mat-mdc-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg{
    font-size: 36px !important;
    line-height: 36px !important;
    height: 36px !important;
    width: 36px !important;
    vertical-align: middle !important;
}
.mat-mdc-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg{
    font-size: 48px;
    line-height: 48px;
    height: 48px;
    width: 48px;
    vertical-align: middle;
}
.mat-icon.caret{
    width: 14px;
    overflow: visible;
}
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-mdc-button.flex-row-button .mat-button-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
}
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-wrapper{
    pointer-events: none;
}
/*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.mat-mdc-list.reviews .mat-mdc-list-item .mat-list-item-content{
    align-items: start;
}
.mat-step-text-label{
    text-transform: uppercase;
}
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label-content{
    font-size: 16px;
}
.mat-mdc-row, .mat-mdc-header-row{
    border-bottom-color: rgba(0,0,0,.12);
}

.app .mat-drawer {
    background-color: transparent !important;
}

.filter-sidenav .mat-drawer-inner-container{
    //height: auto;
    height: calc(100vh - 104px);
    box-sizing: border-box;
    .mat-expansion-panel{
        border-radius: 0 !important;
    }   
}

.app-dropdown.mat-mdc-menu-panel{
    min-width: 60px;
} 
.app-dropdown .mat-mdc-menu-item{
    height: 36px;
    line-height: 36px;
    font-size: 14px;
}
.account-icon{
    margin-top: -2px;
    margin-right: 2px;
}
.app-dropdown.account{
    .mat-mdc-menu-content{
        padding: 0;
        .user-info{
            padding: 16px;
            img{
                margin-right: 16px;
                border-radius: 3px;
            }
            .mat-icon{
                margin-top:-2px;
                color: #fbc02d;
            }
        }
    }    
}
.categories-dropdown.mat-mdc-menu-panel{
    max-height: 350px;
}
.categories-dropdown .mat-mdc-menu-item{
    height: 24px;
    min-height: 24px;
    line-height: 24px;
    font-size: 14px;
    border: none;
    width: 100%;
}
.sub-category button{
    padding-left: 30px;
}
.sub-category .sub-category button{
    padding-left: 50px;
}

.p-0{
    padding: 0 !important;
}
.p-1{
    padding: 10px !important;
}
.p-16 {
  padding: 16px !important;
}
.p-2{
    padding: 20px !important;
}
.p-3{
    padding: 30px !important;
}
.py-1{
    padding: 10px 0 !important;
}
.py-2{
    padding: 20px 0 !important;
}
.py-3{
    padding: 30px 0 !important;
}
.py-4{
    padding: 40px 0 !important;
}
.py-5{
    padding: 50px 0 !important;
}
.px-1{
    padding: 0 10px !important;
}
.px-2{
    padding: 0 20px !important;
}
.px-3{
    padding: 0 30px !important;
}
.px-4{
    padding: 0 40px !important;
}
.px-5{
    padding: 0 50px !important;
}
.pl-3{
    padding-left: 30px;
}
.m-0{
    margin: 0 !important;
}
.mt-1{
    margin-top: 10px !important;
}
.mt-2{
    margin-top: 10px !important;
}
.mt-3{
    margin-top: 30px !important;
}
.mr-1{
    margin-right: 10px !important;
}

.primary-text-dark {

}

.primary-text-light {
  .logo-toolbar {
    .mdc-button {
      color: #fff;
    }
  }
  .mat-mdc-raised-button.mat-primary {
    color: #fff;
  }

  .btn-primary {
    &.selected, &.phase-active {
      color: #fff;
    }
  }
}

.secondary-text-dark {
 .mat-mdc-raised-button.mat-accent {
    color: #000;
  }

  .btn-accent, .mat-mdc-menu-item, .mdc-button {
    &.selected, &.phase-active {
      color: #000;
    }
  }
}

.secondary-text-light {
  .mat-mdc-raised-button.mat-accent {
    color: #fff;
  }

  .mdc-checkbox__checkmark {
    color: #fff !important;
  }

  .btn-accent, .mat-mdc-menu-item, .mdc-button {
    &.selected, &.phase-active {
      color: #fff;
    }
  }
}

.d-block{
    display: block;
}

.info-bar{
    margin-top: 30px;
    .mat-mdc-card{
        height: 100px;
        .content{
            margin-left: 10px;
            p{
                font-weight: 500;
            }
            span{
                font-size: 13px;
            }
        }
    }
}


.social-icon{
    width: 48px;
    height: 48px;
    cursor: pointer;
    fill: #8f8f8f;
    outline: none;
}


.app{  
    &.main-toolbar-fixed{
        #header-toolbar{
            height: 70px;
            position: fixed;
            top: 0px;
            width: 100%;
            z-index: 2;
            box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 
                        0 2px 2px 0 rgba(0,0,0,.14), 
                        0 1px 5px 0 rgba(0,0,0,.12);

          .store-logo img {
            height: 70px;
          }
        } 
        .main {
            padding-top: 80px;
        }       
    }
}

.full-height {
    min-height: calc(100vh - $toolbar-height - $footer-height);
}

@import './partial-styles/toolbar';
@import './partial-styles/top-navbar';
@import './partial-styles/carousel';
@import './partial-styles/product';
@import './partial-styles/account';



/******* Back to top *******/
.back-to-top{
    position: fixed;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 999999;
    right: 20px;
    bottom: 20px;
    opacity: .5;
    color: #fff;
    background-color: rgba(0,0,0,.75);
    border-radius: 4px;  
    transition: width 2s;
    transition-timing-function: ease-in-out;
            &:hover{
        opacity: 0.9;
    }    
}

/******* Responsive *******/
// Extra small devices (portrait phones, less than 576px)
@media (max-width: calc($breakpoint-xs - 1px)) { 
    .top-toolbar.mat-toolbar-row{
        padding: 0;
        .top-menu{
            margin-right: 0;
            a{
                min-width: 56px;
            }
        }
        .sidenav-toggle{
            min-width: 52px;
        }
    } 
    .search-form input[type=text]{
        width: 160px;
    }
    .search-form .categories{
        width: 140px;
    }

}

// Small devices (landscape phones, 576px and up)
@media (min-width: $breakpoint-xs) and (max-width: calc($breakpoint-sm - 1px)) {
    .top-toolbar.mat-toolbar-row{
        padding: 0;
        .top-menu{
            margin-right: 0;
        }
        .sidenav-toggle{
            min-width: 52px;
        }
    } 
}

// Medium devices (tablets, 768px and up)
@media (min-width: $breakpoint-sm) and (max-width: calc($breakpoint-md - 1px)) {
    .top-toolbar.mat-toolbar-row{
        padding: 0;
        .top-menu{
            margin-right: 0;
        }
        .sidenav-toggle{
            min-width: 52px;
        }
    } 

}

// Styles for devices above the small breakpoint
@media (min-width: $breakpoint-sm) {
  .d-mobile {
    display: none !important;
  }

  .filter-sidenav .mat-drawer-inner-container {
    height: auto;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {  }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $breakpoint-lg) {  }


::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 0px;
    &:hover{
        background: #cccccc;
    }
    &:active{
        background: #888888; 
    }
}
::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 0px;
    &:hover{
        background: #666666;
    }
    &:active{
        background: #333333; 
    }
}
::-webkit-scrollbar-corner {
    background: transparent;
}



/* perfect-scrollbar */
.ps {
    -ms-touch-action: auto;
    touch-action: auto;
    overflow: hidden !important;
    -ms-overflow-style: none; 
    position: relative;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
        overflow: auto !important; 
    } 
}
.ps__rail-x {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    height: 7px;
    bottom: 0px;
    position: absolute;
}

.ps__rail-y {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    width: 7px;
    right: 0px;
    left: auto !important;
    position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
    opacity: 0.6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
    background-color: #eee;
    opacity: 0.9;
}

.ps__thumb-x {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, height .2s ease-in-out;
    -webkit-transition: background-color .2s linear, height .2s ease-in-out;
    height: 4px;
    bottom: 1px;
    position: absolute;
}

.ps__thumb-y {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, width .2s ease-in-out;
    -webkit-transition: background-color .2s linear, width .2s ease-in-out;
    width: 4px;
    right: 1px;
    position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
    background-color: #999;
    height: 7px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
    background-color: #999;
    width: 7px;
}

@supports (-ms-overflow-style: none) {
    .ps {
        overflow: auto !important;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
        overflow: auto !important;
    }
}


@media (min-width: 1380px) {  
    .theme-container{
        max-width: 1400px;
    }
}

.sidenav .mat-drawer-inner-container{
    overflow-x: hidden;
}

.mat-drawer-content {
  overflow: unset !important;
}

.ngx-slider .ngx-slider-pointer{
    background-color: var(--theme-accent-300) !important;
}
.ngx-slider .ngx-slider-selection{
    background-color: var(--theme-accent-300) !important;
}
.ngx-slider .ngx-slider-pointer:after {  
    background: white !important;
}
.app.green .mat-pseudo-checkbox-checked, .app.green .mat-pseudo-checkbox-indeterminate, .app.green .mat-accent .mat-pseudo-checkbox-checked, .app.green .mat-accent .mat-pseudo-checkbox-indeterminate {
    background: #5f5454 !important;
}
.app.green .mat-pseudo-checkbox {
    color: #5f5454 !important;
}
@media (max-width: 767px) {  
    .mobile-d-none {
        display: none !important;
    }
    .mobile-menu-sort{
        display: flex !important;
        justify-content: space-between!important;
        flex-wrap: wrap!important;
        flex-direction: row !important;    
    }  
    .all-products{
        padding: 15px !important;
    }
    .mobile-product{
      //padding: 15px !important;
    }
}