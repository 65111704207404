$font-family: 'Source Sans Pro', Roboto, sans-serif;
$theme-max-width: 1300px;

$toolbar-height: 104px;
$footer-height: 300px;

$admin-toolbar-height: 56px;
$admin-sidenav-width: 250px;
$sidenav-user-block-height: 156px;

$border-light-color: rgba(0,0,0,0.1);
$amptify-red: rgb(232, 34, 22);

$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;