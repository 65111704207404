/******* Carousel *******/
button.swipe-arrow{
    position: absolute;
    top: 50%;
    margin-top: -22px;
    z-index: 10;
    background-image: none;
}
.swiper-pagination.white .swiper-pagination-bullet{
    background: #fff;
}
.swiper-pagination-bullet-active{
    width: 12px;
    height: 12px;
    vertical-align: -2px;
}
.swiper-button-prev:after, 
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after, 
.swiper-container-rtl .swiper-button-prev:after{
    content: none;
}