/******* Top navbar (menu) *******/
.top-navbar.mat-toolbar {
    height: 48px;
    padding: 0;
    a{
        min-width: 70px;
        border-radius: 0;
        text-transform: uppercase;
        padding: 6px 16px;
    }
}
.cdk-overlay-pane.mega-menu-pane{
    left: 0 !important;
    width: 100%;
    .mat-mdc-menu-panel{
        width: 100%;
        max-width: none;
        border-radius: 0;
        .mat-mdc-menu-content{
            padding: 0;
        }
    }
    .mega-menu-widget{
        position: relative;
        button{
            position: absolute;
            top: 10px;
            left: 10px;
            font-weight: 400;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
        .mat-chip-list{
            position: absolute;
            bottom: 10px;
            left: 10px;
        }
    }
}