/******* Toolbar *******/
.top-toolbar.mat-toolbar-row{
    height: 32px;
    font-size: 13px;
    .sidenav-toggle{
        height: 31px;
        line-height: 31px;
        min-width: 24px;
        padding: 0;
        border-radius: 0;
    }
    .top-menu{
        margin-right: -16px;
        text-transform: uppercase;
        a{           
            text-decoration: none;
            font-weight: 400;
            font-size: 13px;
            line-height: 31px;
            padding: 0 10px;
            min-width: 70px;
            border-radius: 0;
        } 
        a.mobile-menu-icon{
            min-width: 36px;
        }
        .mat-icon.more{
            height: 28px;
        }       
    }
}
.flag-menu-title{
    vertical-align: top !important;
    margin-left: 3px;
}
.top-menu-dropdown .mat-mdc-menu-item img{
    vertical-align: middle;
    margin-right: 8px;
}
.cart-dropdown.mat-mdc-menu-panel{
    padding: 6px 14px;
    font-size: 13px;
    .mat-mdc-list{
        padding: 0;
        .mat-mdc-list-item{
            /*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
            .mat-list-item-content{
                padding: 0; 
                img{
                    width: 70px;
                    margin-left: 10px;
                }          
            }
            /*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
            .mat-list-text>*{
                font-size: 14px;
                font-weight: 500;
            }
            .mat-line:nth-child(n+2){
                font-size: 13px;
                font-weight: 400;
            }
        }
    } 
    .cart-dropdown-footer{
        font-size: 14px;
    }
}

.app-menu-panel.mat-mdc-menu-panel{
    min-width: 60px;
    .mat-mdc-menu-content:not(:empty){
        padding: 0;
    }
    .mat-mdc-menu-item{
        display: flex;
        align-items: center;
        font-size: 12px;
        text-transform: uppercase; 
        .mat-badge-small.mat-badge-above .mat-badge-content{
            top: 8px;
        }
    }
    &.lang{
        min-width: 41px; 
        .mat-mdc-menu-item{
            line-height: 36px;
            height: 36px;
            padding: 0;
            img{
                display: block;
                margin: 0 auto;
            }
        } 
    }
}

.logo-toolbar.mat-toolbar-row{
    height: 104px;

    .store-logo img {
        height: 104px;
        max-width: 100%;
        object-fit: contain;
    }
}
.search-form{
    border-radius: 22px;
    overflow: hidden;
    .categories{
        height: 40px;
        width: 180px;
        border-radius: 0;
    }
    input[type=text]{
        border: 0;
        outline: none;
        padding: 0 34px 0 12px; 
        border-left: 1px solid #ccc;      
    }
    .search-btn{
        margin-left: -20px;
        background: #fff !important;
        box-shadow: none !important;
    }
}
.search-dropdown.mat-mdc-menu-panel{
    border-radius: 26px;
    max-width: 100%;
    min-height: auto;
    .mat-mdc-menu-content{
        padding: 0;
    }
}
.top-cart{
    span{
        font-size: 13px;
        font-weight: 400;
        line-height: 1.2;
    }
}