/******* Product *******/
.product-item{  
  position: relative;
  border-radius:10px !important;
  padding: 16px 16px 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  //padding-bottom: 5px !important;
  /*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
  .mat-chip-list{
    position: absolute;
    top: 10px;
    right: 10px;
    &.list-view{
      left: 10px;
    }
  }
  
  
  img{
    max-width: 100%;
  }
  .category{
    margin-bottom: 4px;
  }
  .title{
    display: block;
    color: inherit;
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.05em;
  }
  .prices{
    margin: 12px 0;
    height: 40px;
    .old-price{
      text-decoration: line-through;
      height: 15px;    
    }
    .new-price{
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #D83621;
    }
  }
}

.product-description {
  ul, ol {
    margin-left: 2em;
  }
}
.old-price{
  text-decoration: line-through;
  text-align: right;
}
.new-price{
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #D83621;
}

.product-tabs-wrap {
  .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: #000;
  }
}